.card {
    background: $body-bg-dark;
    margin-bottom: 1.5em;

    &.opened {
        border: 1px solid $white !important;
        border-radius: 15px !important;
    }

    .card-header {
        cursor: pointer;
        border-bottom: none;

        .title {
            font-size: 21px;
        }

        .accicon {
            float: right;
            font-size: 20px;  
            width: 1.2em;
        }

        &:not(.collapsed) {
            .accicon {
                border: 1px solid $white;
                padding-left: 10px;
                margin-right: 7px;
                border-radius: 100%;
                background: $white;
                color: $black;
                height: 1.5em;
                width: 1.5em;
            }
            .rotate-icon {
                margin-top: 6px;
                transform: rotate(90deg);
            }
        }
    }

    .card-body{
        border-top: none;
        font-size: 16px;
        font-weight: lighter;
        padding-top: 0;
    }
}

.toc-sticky {
    position: sticky;
    top: 8em;
    z-index: 1;
}

ul.faq-table-of-contents {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    max-width: 50%;

    li {
        margin-left: -1em;
        &.nav-item {
            .nav-link {
                color: $font-grey;
                font-size: 20px;
                @include transition(all, 0.5s, ease-in-out);

                &:focus, &:hover, &.active {
                    color: $white;
                }
            }            
        }
    }
}

@media (max-width: 576px) {
    .toc-sticky-mobile {
        background: $body-bg-dark;
        position: sticky;
        z-index: 1;
        top: 5em;
        padding-bottom: 1em;
        padding-top: 1em;
    }

    .card {
        .card-header {
            .title {
                display: inline-block;
                font-size: 19px;
                width: 13em;
            }
        }
    }
}