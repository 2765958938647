.new-footer {
    background-color: $black;
    color: $white;
    padding: 3.4em 4.6em;

    img.logo-filmo {
        width: 14em;
        margin-left: -2em;
        margin-top: -1em;
        height: auto;
    }

    h3 {
        color: #FFF;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 1.5rem;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            margin-bottom: 0.75rem;
        }
    }

    a {
        text-decoration: none;
        transition: color 0.3s ease;
        color: $font-grey;
        font-size: 16px;
        font-weight: 600;
        line-height: normal;

        &:hover {
            color: #fff;
        }
    }

    ul.social-links {
        display: flex;
        list-style: none;
        margin-bottom: 0;
        align-items: center;

        li.footer-links__item {
            margin-bottom: 0;
            display: list-item;

            a.footer-links__link {
                .footer-image {
                    width: 10em;
                    transition: transform 0.3s ease-in-out;

                    &:hover {
                        transform: scale(1.05);
                    }
                }

                .footer-logo {
                    width: 1.5em;
                    height: auto;
                    transition: transform 0.3s ease-in-out;

                    &:hover {
                        transform: scale(1.05);
                    }

                    margin-right: 1.2em;
                }
            }
        }
    }



    .copyright {
        color: $font-grey;
        margin-right: 1.5rem;
        text-align: right;
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
        padding: 2.5em 3em;

        img.logo-filmo {
            width: 12em;
        }

        ul.social-links {
            li.footer-links__item {
                a.footer-links__link {
                    .footer-image {
                        width: 9em;
                    }

                    .footer-logo {
                        width: 1.3em;
                        margin-right: 1em;
                    }
                }
            }
        }
    }

    // Small devices (landscape phones, 576px to 767px)
    @media screen and (min-width: 576px) and (max-width: 767px) {
        padding: 2em 2.5em;

        img.logo-filmo {
            width: 11em;
        }

        ul.social-links {
            flex-wrap: wrap;

            li.footer-links__item {
                a.footer-links__link {
                    .footer-image {
                        width: 8em;
                    }

                    .footer-logo {
                        width: 1.2em;
                        margin-right: 0.8em;
                    }
                }
            }
        }

        .copyright {
            text-align: center;
            margin-right: 0;
            font-size: 15px;
        }
    }

    // Extra small devices (phones, less than 576px)
    @media screen and (max-width: 575px) {
        padding: 1.5em 1em;

        img.logo-filmo {
            width: 10em;
        }

        h3 {
            font-size: 14px;
            margin-bottom: 1rem;
        }

        a {
            font-size: 12px;
        }

        ul.social-links {
            flex-wrap: wrap;

            li.footer-links__item {
                a.footer-links__link {
                    .footer-image {
                        width: 7em;
                    }

                    .footer-logo {
                        width: 1.1em;
                        margin-right: 0.6em;
                    }
                }
            }
        }

        .copyright {
            text-align: center;
            margin-right: 0;
            font-size: 13px;
        }
    }
}