html {
    position: relative;
    min-height: 100%;
}

body {
    font: 400 .875rem 'Nunito', sans-serif;
    background: $black;
    margin-bottom: 0;
    overscroll-behavior: none;
}

.main-container {
    margin-top: 3em;
    margin-bottom: 6em;
    padding-top: 4em;
    padding-left: 7em;
    padding-right: 7em;
    color: $white;

    &.home {
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 0;
        max-width: 100%;

        .home-container {
            position: relative;
            width: 100%;
            min-height: 100vh;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;

            .title {
                h2 {
                    color: #FFF;
                    text-align: center;
                    font-family: Kodchasan;
                    font-size: 40px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 64px;
                    letter-spacing: 0.1px;
                    margin-bottom: 0.5em;
                }

                h3 {
                    color: #FFF;
                    text-align: center;
                    font-family: Nunito;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 36px;
                    letter-spacing: 0.1px;
                    margin-bottom: 2em;
                }

            }



            img.triple-image {
                width: 48em;
                object-fit: cover;
                object-position: center;
            }

            &.about-us-header {
                padding-top: 6em;
                margin-top: -6em;
            }

            &.home-header {
                padding-top: 6em;
                margin-top: -6em;
            }

            &.about-us-instructions-container {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;

                .text-container {
                    .text {
                        text-align: left;
                        margin-bottom: 0;
                    }
                }

                .top {
                    align-items: center;
                    width: 100%;
                }

                .bottom {
                    align-items: center;
                    width: 100%;
                }

                img.qr-code {
                    width: 16em;
                    object-fit: cover;
                    object-position: center;
                    margin-bottom: 2.4em;
                }
            }

            .home-video-container {
                display: flex;
                justify-content: center;
                align-items: center;

                .iphone-notch {
                    position: absolute;
                    top: 1.5%;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 50%;
                    height: 25px;
                    background-color: $black;
                    border-bottom-left-radius: 1em;
                    border-bottom-right-radius: 1em;
                    z-index: 2;

                    // Face ID sensor
                    &::before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        right: 25%;
                        transform: translateY(-50%);
                        width: 6px;
                        height: 6px;
                        background-color: #1a1a1a;
                        border: 2px solid #333;
                        border-radius: 50%;
                    }

                    // Speaker mesh
                    &::after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 35%;
                        height: 3px;
                        background-color: #1a1a1a;
                        border: 1px solid #333;
                        border-radius: 3px;
                    }

                    // Front camera
                    .face-id-sensor {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 25%;
                        transform: translateY(-50%);
                        width: 6px;
                        height: 6px;
                        background-color: #1a1a1a;
                        border: 2px solid #333;
                        border-radius: 50%;
                    }
                }

                video {
                    position: absolute;
                    border-radius: 1.7em;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 92%;
                    height: 94%;
                    object-fit: fill;
                    z-index: 0;
                    overflow: hidden;
                }

                img.img-video {
                    width: 100%;
                    object-fit: cover;
                    object-position: center;
                    z-index: 1;
                }

                &.double-image {
                    img.img-double {
                        width: 35.2em;
                        object-fit: cover;
                        object-position: center;
                    }
                }

                &.image-video-container-right {
                    img.img-left-shadow {
                        width: 35.2em;
                        object-fit: cover;
                        object-position: center;
                    }

                    .video-right-container {
                        position: relative;
                        margin-left: -15em;
                        width: 19em;
                    }
                }

                &.image-video-container-left {
                    .video-left-container {
                        width: 19em;
                        position: relative;
                        margin-right: 1em;
                    }

                    img.img-right {
                        width: 17.2em;
                        object-fit: cover;
                        object-position: center;
                    }
                }

                &.video-only {
                    .video-container {
                        position: relative;
                        width: 19em;
                        display: block;
                        margin: auto;

                        .phone-shadow {
                            position: absolute;
                            width: 117%;
                            height: 107%;
                            top: 0;
                            right: 0;
                            z-index: -1;
                            background: url('../../vendors/images/phone-shadow.png') lightgray 0px 0px / 100% 100% no-repeat;
                            background-color: transparent;
                            mix-blend-mode: multiply;
                            object-fit: contain;
                        }
                    }
                }

            }

            &.bg-gradient-circle {
                background: radial-gradient(449.54px at 24.1% 57.3%,
                        #7F4FE2 0%,
                        #CF76E3 36.7998%,
                        #E983E3 42.1425%,
                        #FFADEE 46.5%,
                        #C474EB 52.5%,
                        #B46CE9 57.3205%,
                        #7F4FE2 100%);
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            }

            &.bg-gradient-top {
                background: linear-gradient(120deg,
                        #7F4FE2 9.08%,
                        #A35EE3 15.43%,
                        #CA6DE4 22.18%,
                        #FFADEE 29.92%,
                        #CF6FE4 37.86%,
                        #A85FE3 43.22%,
                        #7F4FE2 48.77%);
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            }

            &.bg-gradient-circle-bottom {
                background: radial-gradient(942.773px at 50% 99%,
                        #7F4FE2 0%,
                        #CF76E3 36.8%,
                        #E983E3 42.14%,
                        #FFADEE 46.5%,
                        #C474EB 52.5%,
                        #B46CE9 57.32%,
                        #7F4FE2 100%);
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

            }

            &.bg-purple {
                background: #7F4FE2;
            }




            .home-download-qr {
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 114px;
                    margin-left: 1.5em;
                }

                h3 {
                    color: #FFF;
                    text-align: center;
                    font-family: Nunito;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 36px;
                    letter-spacing: 0.1px;
                }

                .qr-button {
                    color: $white;
                    margin-bottom: 2em;
                    margin-top: 1em;
                    padding: 10px 20px;
                    border-radius: 48px;
                    border: 2px solid $white;
                    text-decoration: none;
                    text-align: center;
                    font-size: 13px;
                    font-weight: 600;
                    transition: all 0.3s ease;
                    display: none;

                    &:hover {
                        transform: translateY(-2px);
                    }
                }
            }

            .shopify-button {
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 10em;
                }
            }
        }


    }

    h1.page-title-header {
        font-size: 30px;
        line-height: 41px;
    }

    h3 {
        font-size: 26px;
    }

    .submitted-header {
        font-size: 30px;
        line-height: 41px;
        margin-top: 60px;
        margin-bottom: 30px;
    }

    .submitted-header-h3 {
        font-size: 18px;
        line-height: 25px;
    }

    .home-banner {
        .image-container {
            img.home-image {
                width: 100%;
                object-fit: cover;
                object-position: center;
                display: block;
                margin: auto;
            }

            img.phone-mockup {
                width: 19.8em;
                object-fit: cover;
                object-position: center;
                display: block;
                margin: auto;
            }
        }
    }

    h2 {
        &.home-h2-mobile {
            font-size: 34px;
            display: none;
            font-weight: 600;
        }
    }

    .home-about {
        text-align: center;

        h2 {
            margin-bottom: 1em;
            font-size: 36px;
            font-weight: 600;
        }

        h3 {
            line-height: 1.3em;
            margin-bottom: 1.5em;
            font-weight: lighter;
        }

        img.img-download-google-play {
            width: 217px;
            height: 64px;
        }

        img.img-download-apple-store {
            width: 194px;
            height: 64px;
        }

        &.for-sellers {
            margin-top: 0;

            h2 {
                font-style: normal;
                font-weight: 700;
                font-size: 4.5vw;
                line-height: 5.5vw;
                align-items: center;
                text-align: center;
                letter-spacing: 0.1px;
                color: #FFFFFF;
                margin-bottom: 0.5em;
            }

            h3 {
                font-weight: 300;
                font-size: 1.6vw;
                line-height: 2.4vw;
                text-align: center;
                letter-spacing: 0.1px;
            }
        }

    }

    .form-centered {
        width: 30%;
        display: block;
        margin: auto;
        margin-top: 0;
    }

    &::-webkit-scrollbar {
        height: 6px;
        width: 6px;
        background: $body-bg-dark;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:vertical {
        background: $white;
        border-radius: 10px;
    }

    &.full-height {
        height: 100vh;
    }

    .full-height-centered {
        position: relative;
        top: 25%;

        .download-wrapper {
            flex-direction: row;

            img.img-download-google-play {
                width: 195px;
                height: 65px;
            }

            img.img-download-apple-store {
                width: 195px;
                height: 65px;
            }
        }
    }
}

@media (min-width: 1281px) {

    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 100%;

    }

    .main-container {
        max-height: 90vh;
        overflow-x: hidden;
        overflow-y: auto;

        &.home {
            max-height: 100vh;
        }
    }
}

@media (min-width: 1025px) and (max-width: 1280px) {
    .main-container {
        max-height: 90vh;
        overflow-x: hidden;
        overflow-y: auto;

        &.home {
            max-height: 100vh;
        }
    }
}

/* ipad, tablet (portrait) */
@media (min-width: 768px) and (max-width: 1024px) {
    div.row {
        margin-left: 0;
        margin-right: 0;
    }

    .main-container {
        margin-top: 7em;
        margin-bottom: 10em;
        padding-top: 1em;
        padding-left: 1em;
        padding-right: 1em;

        &.home {
            margin-top: 0;
            margin-bottom: 0;

            .home-container {
                .home-video-container {
                    .iphone-notch {
                        height: 16.25px;
                        border-bottom-left-radius: 0.65em;
                        border-bottom-right-radius: 0.65em;

                        &::before {
                            width: 3.9px;
                            height: 3.9px;
                            border: 1.3px solid #333;
                        }

                        &::after {
                            height: 1.95px;
                            border: 0.65px solid #333;
                            border-radius: 1.95px;
                        }

                        .face-id-sensor {
                            width: 3.9px;
                            height: 3.9px;
                            border: 1.3px solid #333;
                        }
                    }

                    video {
                        border-radius: 1.105em;
                    }

                    img.img-video {
                        width: 100%;
                    }

                    &.double-image {
                        img.img-double {
                            width: 21.84em;
                        }
                    }

                    &.image-video-container-right {
                        img.img-left-shadow {
                            width: 21.84em;
                        }

                        .video-right-container {
                            margin-left: -9.646em;
                            width: 12.22em;
                        }
                    }

                    &.image-video-container-left {
                        .video-left-container {
                            width: 12.22em;
                            margin-right: 0.65em;
                        }

                        img.img-right {
                            width: 10.738em;
                        }
                    }

                    &.video-only {
                        .video-container {
                            width: 14.3em;
                        }
                    }
                }
            }
        }

        .home-banner {

            .image-container {
                margin: .5rem !important;

                img.phone-mockup {
                    width: 19.8em;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }

        h2 {
            &.home-h2-mobile {
                display: block;
            }
        }

        .home-about {
            h2 {
                &.home-h2-desktop {
                    display: none;
                }
            }

            .download-wrapper {
                flex-direction: row;

                img.img-download-google-play {
                    width: 255px;
                    height: 76px;
                }

                img.img-download-apple-store {
                    width: 228px;
                    height: 76px;
                }
            }
        }

        .form-centered {
            width: 100%;
        }

        &.full-height {
            height: 75vh;
        }
    }
}

@media (min-width: 576px) and (max-width: 768px) {
    div.row {
        margin-left: 0;
        margin-right: 0;
    }

    .main-container {
        &.home {
            margin-top: 0;
            margin-bottom: 0;

            .home-container {
                flex-direction: column;
                justify-content: center;
                padding-right: 0;

                .title {
                    h2 {
                        font-size: 22px;
                        font-weight: 600;
                        line-height: 33px;

                    }

                    h3 {
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 23px;
                    }


                }

                .home-video-container {
                    margin-bottom: 3em;
                }

                &.phone-reverse {
                    flex-direction: column-reverse;
                    justify-content: center;
                }



                &.bg-gradient-circle {
                    background:
                        radial-gradient(296px 290.311px at 49.87% 37.61%,
                            #7F4FE2 0%,
                            #CF76E3 39.62%,
                            #E983E3 42.14%,
                            #FFADEE 46.5%,
                            #C474EB 52.5%,
                            #B46CE9 57.32%,
                            #7F4FE2 100%);
                }

                &.bg-gradient-top {
                    background: linear-gradient(0deg,
                            #7F4FE2 36.43%,
                            #A35EE3 46.6%,
                            #CA6DE4 57.41%,
                            #FFADEE 69.8%,
                            #CF6FE4 82.52%,
                            #A85FE3 91.1%,
                            #7F4FE2 100%);
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                }

                &.bg-gradient-circle-bottom {
                    min-height: 101vw;
                    max-height: 101vw;
                    background: radial-gradient(35em at 50% 99%,
                            #CF76E3 36.7998%,
                            #E983E3 42.1425%,
                            #FFADEE 46.5%,
                            #C474EB 52.5%,
                            #B46CE9 57.3205%,
                            #7F4FE2 100%);
                }

                .home-download-qr {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        display: none;
                    }

                    h3 {
                        display: none;
                    }

                    .qr-button {
                        display: block;
                        
                    }
                }
            }

        }

        .home-banner {
            .image-container {
                margin: 1.5rem !important;

                img.phone-mockup {
                    width: 13.8em;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }
    }
}

/* phone */
@media (max-width: 576px) {
    div.row {
        margin-left: 0;
        margin-right: 0;
    }

    .main-container {
        margin-top: 5em;
        margin-bottom: 10em;
        padding-top: 1em;
        padding-left: 1em;
        padding-right: 1em;
        padding-bottom: 2em;

        &.home {
            margin-top: 0;
            margin-bottom: 0;


            .home-container {
                flex-direction: column;
                justify-content: center;
                padding-right: 0;
                min-height: 160vw;

                .title {
                    h2 {
                        font-size: 22px;
                        font-weight: 600;
                        line-height: 33px;
                    }

                    h3 {
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 23px;
                    }

                }

                img.triple-image {
                    width: 25em;
                    margin-bottom: 3em;
                }

                &.phone-reverse {
                    flex-direction: column-reverse;
                    justify-content: center;
                }

                &.about-us-instructions-container {
                    justify-content: space-between;
                    padding: 2.5em;

                    .text-container {
                        margin-bottom: 3em;

                        .text {
                            text-align: center;
                        }
                    }

                    .top {
                        align-items: center;
                        width: 100%;
                    }

                    .bottom {
                        img.qr-code {
                            display: none;
                        }

                        .download {
                            display: none;
                        }
                    }
                }

                .home-video-container {
                    margin-bottom: 3em;

                    .iphone-notch {

                        height: 12.5px;
                        border-bottom-left-radius: 0.5em;
                        border-bottom-right-radius: 0.5em;

                        // Face ID sensor
                        &::before {

                            width: 3px;
                            height: 3px;
                            border: 1px solid #333;
                        }

                        // Speaker mesh
                        &::after {
                            height: 1.5px;
                            border: 0.5px solid #333;
                            border-radius: 1.5px;
                        }

                        // Front camera
                        .face-id-sensor {

                            width: 3px;
                            height: 3px;
                            border: 1px solid #333;
                        }
                    }

                    video {
                        border-radius: 0.85em;

                    }

                    img.img-video {
                        width: 100%;

                    }

                    &.double-image {
                        img.img-double {
                            width: 16.8em;
                        }
                    }

                    &.image-video-container-right {
                        img.img-left-shadow {
                            width: 16.8em;
                        }

                        .video-right-container {
                            margin-left: -7.42em;
                            width: 9.4em;
                        }
                    }

                    &.image-video-container-left {
                        .video-left-container {
                            width: 9.4em;
                            margin-right: 0.5em;
                        }

                        img.img-right {
                            width: 8.26em;
                        }
                    }

                    &.video-only {
                        .video-container {
                            width: 11em;
                        }
                    }

                }

                &.bg-gradient-circle {
                    background:
                        radial-gradient(19.73em 19.33em at 49.87% 37.61%,
                            #7F4FE2 0%,
                            #CF76E3 39.62%,
                            #E983E3 42.14%,
                            #FFADEE 46.5%,
                            #C474EB 52.5%,
                            #B46CE9 57.32%,
                            #7F4FE2 100%);
                }

                &.bg-gradient-top {
                    background: linear-gradient(0deg,
                            #7F4FE2 36.43%,
                            #A35EE3 46.6%,
                            #CA6DE4 57.41%,
                            #FFADEE 69.8%,
                            #CF6FE4 82.52%,
                            #A85FE3 91.1%,
                            #7F4FE2 100%);
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                }

                &.bg-gradient-circle-bottom {
                    min-height: 101vw;
                    max-height: 101vw;
                    background: radial-gradient(35em at 50% 99%,
                            #CF76E3 36.7998%,
                            #E983E3 42.1425%,
                            #FFADEE 46.5%,
                            #C474EB 52.5%,
                            #B46CE9 57.3205%,
                            #7F4FE2 100%);
                }

                .home-download-qr {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        display: none;
                    }

                    h3 {
                        display: none;
                    }

                    .qr-button {
                        display: block;
                    }
                }

                .shopify-button {
                    img {
                        width: 7em;
                    }
                }
            }


        }


        .home-banner {
            flex-direction: column;

            .image-container {
                margin: 1.5rem !important;

                img.phone-mockup {
                    width: 13.8em;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }

        h2 {
            &.home-h2-mobile {
                display: block;
            }
        }

        .home-about {
            margin-top: 0;

            h2 {
                margin-bottom: 1em;

                &.home-h2-desktop {
                    display: none;
                }
            }

            .download-wrapper {
                flex-direction: column;

                img {
                    width: 194px;
                }
            }

            &.for-sellers {
                margin-top: 0;

                h2 {
                    margin-bottom: 1.5em;
                    font-size: 36px;
                    font-weight: 600;
                    line-height: 1.2;
                }

                h3 {
                    line-height: 1.3em;
                    margin-bottom: 1.5em;
                    font-weight: lighter;
                    font-size: 26px;
                }
            }
        }

        .form-centered {
            width: 100%;
        }

        &.full-height {
            height: 55vh;
        }
    }
}