.pagination {
	.page-item {
		.page-link {
			background: transparent;
			color: $font-grey;
			margin: 0;
			border: 0;
			// @include roundedCorners(2);
			min-width: 28px;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			&:hover {
				// background: $body-bg;
				color: $white;
			}
			i {
				font-weight: 700;
				vertical-align: middle;
				font-size: .95rem;
			}
			&:focus {
				outline: 0;
				box-shadow: none;
			}
		}
		&.active {
			.page-link {
				// background: $primary-color;
				color: $white;
				pointer-events: none;
				// box-shadow: 0 0 0 0.2rem rgba(90, 141, 238, 0.4);
			}
		}
		&.disabled {
			.page-link {
				opacity: 0.6;
				// background: $primary-light;
				color: rgba(255, 255, 255, 0.4);				
			}
		}
	}
	&.primary {
		.page-item {
			&.active {
				.page-link {
					background: $primary-dark;
					box-shadow: 0 0 0 0.2rem rgba(90, 141, 238, 0.4);
				}
			}
			&.disabled {
				.page-link {
					background: $primary-color;
				}
			}
		}
	}
	&.secondary {
		.page-item {
			&.active {
				.page-link {
					background: $secondary-dark;
					box-shadow: 0 0 0 0.2rem rgba(220, 41, 67, 0.4);
				}
			}
			&.disabled {
				.page-link {
					background: $secondary-color;					
				}
			}
		}
	}
	&.success {
		.page-item {
			&.active {
				.page-link {
					background: $green;
					box-shadow: 0 0 0 0.2rem rgba(0, 142, 23, 0.4);
				}
			}
			&.disabled {
				.page-link {
					background: $green;
				}
			}
		}
	}
	&.info {
		.page-item {
			&.active {
				.page-link {
					background: $blue;
					box-shadow: 0 0 0 0.2rem rgba(0, 71, 177, 0.5);
				}
			}
			&.disabled {
				.page-link {
					background: $blue;
				}
			}
		}
	}
	&.warning {
		.page-item {
			&.active {
				.page-link {
					background: $yellow;
					box-shadow: 0 0 0 0.2rem rgba(242, 174, 8, 0.3);
				}
			}
			&.disabled {
				.page-link {
					background: $yellow;
				}
			}
		}
	}
	&.danger {
		.page-item {
			&.active {
				.page-link {
					background: $red;
					box-shadow: 0 0 0 0.2rem rgba(210, 0, 0, 0.4);
				}
			}
			&.disabled {
				.page-link {
					background: $red;
				}
			}
		}
	}
	&.rounded {
		.page-item {
			.page-link {
				@include roundedCorners(30);
				&:hover {
					@include roundedCorners(30);
				}
			}
			&.active {
				.page-link {
					@include roundedCorners(30);
				}
			}
		}
	}
}