// Black, White Colors
$white: #ffffff;
$black: #000000;
$purple: rgb(219 0 255);

$filmo-gray: #8A8B96;
$filmo-green: #52B788;

// Light, Dark Colors
$light-color: #8699d0;
$dark-color: #141d2f;
$font-grey: #8A8B96;
$mute-color: #48516b;

// Body Colors
$body-bg: #1A233A;
$body-bg-light: #bcd0f7;
$body-bg-dark: #000000;
$body-bg-darker: #e4dacd;

$card-bg: #272E48;
$card-bg-light: #4b546f;
$card-bg-lighter: #2c3b5a;
$card-bg-dark: #4b546f;
$card-border-bg: #343f5f;
$card-border-bg-dark: #e0d9cc;
$shadow-color: #e5e9ec;
$light-bg: #eff1f5;
$dark-bg: #262b31;

// Border Colors
$border-bg: #3a4669;
$border-dark-bg: #d2b999;
$form-border: #596280;
$form-border-dark: #b5bbc5;
$input-bg: #1A233A;

// Font Colors
$font-color: #bcd0f7;
$font-color-dark: #1a243a;
$font-color-light: #8A99B5;

// Primary, Secondary Colors
$primary-color: #0047b1;
$primary-light: #0052ce;
$primary-dark: #003d98;
$secondary-color: #b10019;
$secondary-light: #bf001b;
$secondary-dark: #9c0016;

// Header Color
$header-bg: #f6f8fa;
$header-shadow: #cfd7da;
$header-bg-dark: #e6ebf1;
$header-color: #ecb76c;
$header-border: #d9dde0;

// Navbar Color
$navbar-bg: #2E343C;
$navbar-text: #DFE5EC;
$navbar-active-bg: #F4F5FB;

// Sidebar Color
$sidebar-bg: #121929;
$sidebar-brand: #212121;
$sidebar-text: #9E9E9E;
$sidebar-text-light: #595959;
$sidebar-icon: #8592B1;
$sidebar-hover-bg: #2E3B5A;
$sidebar-active-bg: #212B42;
$sidebar-submenu-hover: #1e2842;
$sidebar-icon-bg: #1F2328;



// Table Colors
$table-header: #0047b1;
$table-border: #2b3958;
$table-border-light: #e3e8ef;
$table-body: #1A243A;
$table-body-dark: #161F33;
$table-hover: #272F47;
$table-color: #BCD0F7;
$table-dark: #5FA22D;
$table-dark-border: #7AC146;

// Colors
$red: #FB0202;
$dark-red: #AB0000;
$light-red: #FF3434;
$green: #52B788;
$dark-green: #006912;
$light-green: #C0d64A;
$yellow: #F1AE07;
$dark-yellow: #C38B00;
$light-yellow: #F0C219;
$blue: #0047B1;
$dark-blue: #00409E;
$darker-blue: #0065FD;
$light-blue: #0765F1;
$pink: #f77EB9;
$orange: #f09819;
$dark-orange: #FF5858;
$brown: #AF772B;

$btn-filmo-background: #000;
$btn-filmo-border: #f1dcff;



$m-primary: #1646d6;
$m-primary-dark: #1149ef;
$m-primary-font-color: #2357f1;

$m-secondary: #d00d4c;
$m-secondary-dark: #e80e55;
$m-secondary-font-color: #e61c5e;

$m-blue: #0047b1;
$m-blue-dark: #0055d4;
$m-blue-font-color: #0063f7;

$m-red: #d20000;
$m-red-dark: #da0000;
$m-red-font-color: #b50000;

$m-yellow: #f1ae07;
$m-yellow-dark: #ffb806;
$m-yellow-font-color: #e8a600;

$m-green: #008e18;
$m-green-dark: #009a1a;
$m-green-font-color: #009619;

// Social Colors
$facebook: #3B5998;
$twitter: #55ACEE;
$linkedin: #007BB5;
$gplus: #E02F2F;


/*************** 2.Mixins ***************/

// Gradients
@mixin linear-gradient($fromColor, $toColor) {
	background-color: $toColor; /* Fallback Color */
	background-image: -webkit-gradient(linear, left top, left bottom, from($fromColor), to($toColor)); /* Saf4+, Chrome */
	background-image: -webkit-linear-gradient(right, $fromColor, $toColor); /* Chrome 10+, Saf5.1+, iOS 5+ */
	background-image: -moz-linear-gradient(right, $fromColor, $toColor); /* FF3.6 */
	background-image: -ms-linear-gradient(right, $fromColor, $toColor); /* IE10 */
	background-image: -o-linear-gradient(right, $fromColor, $toColor); /* Opera 11.10+ */
	background-image: linear-gradient(right, $fromColor, $toColor);
}
@mixin linear-gradient-tb($top, $bottom) {
	background: $top; /* Old browsers */
	background: -moz-linear-gradient(top, $top 0%, $bottom 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$top), color-stop(100%,$bottom)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, $top 0%,$bottom 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, $top 0%,$bottom 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top, $top 0%,$bottom 100%); /* IE10+ */
	background: linear-gradient(to bottom, $top 0%,$bottom 100%); /* W3C */
}
@mixin linear-gradient-tmb($startColor: #ffffff, $midColor: #eeeeee, $colorStop: 50%, $endColor: #cccccc) {
	background-color: mix($midColor, $endColor, 80%);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from($startColor), color-stop($colorStop, $midColor), to($endColor));
	background-image: -webkit-linear-gradient($startColor, $midColor $colorStop, $endColor);
	background-image: -moz-linear-gradient(top, $startColor, $midColor $colorStop, $endColor);
	background-image: -o-linear-gradient($startColor, $midColor $colorStop, $endColor);
	background-image: linear-gradient($startColor, $midColor $colorStop, $endColor);
	background-repeat: no-repeat;
}

// Transition
@mixin transition($transition-property, $transition-time, $method) {
	-webkit-transition: $transition-property $transition-time $method;
	-moz-transition: $transition-property $transition-time $method;
	-ms-transition: $transition-property $transition-time $method;
	-o-transition: $transition-property $transition-time $method;
	transition: $transition-property $transition-time $method;
}

// Box Sizing
@mixin box-sizing($string){
	-webkit-box-sizing: $string;
	-moz-box-sizing: $string;
	box-sizing: $string;
}

// Box Shadow
@mixin box-shadow($off-y, $off-x, $blur, $color){
	-moz-box-shadow: $off-y $off-x $blur $color; /* FF3.5+ */
	-webkit-box-shadow: $off-y $off-x $blur $color; /* Saf3.0+, Chrome */
	box-shadow: $off-y $off-x $blur $color; /* Opera 10.5, IE9 */
}
@mixin box-shadow-inset($top, $left, $blur, $color, $inset:"") {
	-webkit-box-shadow: $top $left $blur $color $inset;
	-moz-box-shadow: $top $left $blur $color $inset;
	box-shadow: $top $left $blur $color $inset;
}

// Border Radius
@mixin roundedCorners($size) {
	-webkit-border-radius: $size + px;
	-moz-border-radius: $size + px;
	border-radius: $size + px;
}
@mixin roundedCornersAll($size1, $size2, $size3, $size4) {
	-webkit-border-radius: $size1 $size2 $size3 $size4;
	-moz-border-radius: $size1 $size2 $size3 $size4;
	border-radius: $size1 $size2 $size3 $size4;
}

// Transform
@mixin transform($args){
	-webkit-transform: $args;
	-moz-transform: $args;
	-o-transform: $args;
	-ms-transform: $args;
	transform: $args;
}

// Opacity
@mixin opacity($opacity) {
	opacity: $opacity / 100;
	filter: alpha(opacity=$opacity);
}
