input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px $black inset !important;
	-webkit-text-fill-color: $white !important;
}

fieldset {
    border: 2px solid $white;   
	border-radius: 15px; 
    padding: 0 .5em;
	position: relative;
	legend {
		color: $white;
		margin-bottom: -5px;
		font-size: .825rem;
		padding-left: 10px;
		padding-right: 10px;
		width: auto;
		margin-left: 10px;
	}
	.toggle-password {
		cursor: pointer;
		position: absolute;
		bottom: 14px;
		right: 6px;
	}
}

input[type=text], input[type=password], input[type=email], input[type=number], textarea.form-control, select {
  @include transition(all, 0.3s, ease-out);
  outline: none !important;
  margin: 5px 1px 3px 0px;
  border-radius: 12px;
  background: $body-bg-dark;
  border: none;
  color: $white;

	&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
		color: $font-grey;
		opacity: 1;
	}	

	&:-ms-input-placeholder { /* Internet Explorer 10-11 */
		color: $font-grey;
	}

	&::-ms-input-placeholder { /* Microsoft Edge */
		color: $font-grey;
	}
}
 
input[type=text]:focus, input[type=password]:focus, input[type=email]:focus, input[type=number]:focus, textarea.form-control:focus, select:focus {
	/*
	box-shadow: 0 0 20px rgba(219, 0, 255, 1) !important;  
	border: 1px solid rgba(219, 0, 255, 1) !important;
	*/
	caret-color: $white;
	color: $white;
	background: $body-bg-dark;
  	border: none;
	margin: 5px 1px 3px 0px;
	box-shadow: none;
}

input[type=text] {
	&.as-dropdown {
		border-left: solid 1px #596280 !important;
		border-top: solid 1px #596280 !important;
		border-bottom: solid 1px #596280 !important;
		border-right: none !important;
		border-radius: 14px 0 0 14px !important;

		&:focus {
			box-shadow: none !important;  
			margin: 5px 1px 3px 0px !important;
		}

		&.opened {
			border-bottom: none !important;
    		border-radius: 14px 0 0 0 !important;
		}
	}
}

.form-group {
    &.with-icon {
        position: relative;
        input[type=text] {
            padding-left: 33px !important;
        }
    }
    .inner-icon {
        position: absolute;
        top: 33px;
        left: 10px;
		z-index: 4;
    }
}

.multiple-checkbox {
	cursor: pointer;
}

.dropdown-search {
    position: absolute;
    background: #1A233A;
    color: #BCD1F7;
    left: 0;
    top: 39px;
    width: 100%;
    z-index: 999;
    display: none;
    max-height: 300px;
    overflow: auto;
    padding: 10px;
    border: solid 1px #596280;
	border-top: none;
	border-radius: 0 0 14px 14px;
}

.input-group-carret {
  margin-top: 15px;
  cursor:pointer;

  &.carret-as-dropdown {
	background: $body-bg;
	border-right: solid 1px #596280 !important;
	border-top: solid 1px #596280 !important;
	border-bottom: solid 1px #596280 !important;
	border-left: none !important;
	border-radius: 0 14px 14px 0 !important;
	margin-top: 5px;
	margin-bottom: 3px;
	padding-top: 8px;
	padding-right: 10px;

	&.opened {
		border-bottom: none !important;
    	border-radius: 0 14px 0 0 !important;
	}
  }
}

ul.messages-list {
	list-style: none;

	> li {
		margin-right: 3em;

		div.alert {
			text-align: center;
		}
	}
}

.alert {
	&.alert-error {
		color: #721c24;
		background-color: #f8d7da;
		border-color: #f5c6cb;
	}
}