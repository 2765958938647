.text-muted {
	color: $font-grey !important;
}
.text-filmo-glow {
	color: $purple;
}
a.text-filmo-glow {
	color: $purple !important;
}
button.btn-filmo-glow, a.btn-filmo-glow {
	border: 2.62087px solid $white;
	box-shadow: $purple 0px 1px 8px 1px, $purple 0px 1px 5px 1px inset;
	border-radius: 40.2319px;
	font-size: 16px;
	color: $white !important;
	background-color: $btn-filmo-background;
	padding: .5em 2em;
	@include transition(all, 0.5s, ease-in-out);

	&:hover {
		color: $white !important;
		border-color: $btn-filmo-border;
	}
}
button.btn-filmo, a.btn-filmo {
	border: 2.62087px solid $white;
  	border-radius: 40.2319px;
  	font-size: 16px;
  	color: $white !important;
  	background-color: $btn-filmo-background;
	padding: .5em 2em;
	@include transition(all, 0.5s, ease-in-out);

	&:hover {
		color: $white !important;
  		border-color: $btn-filmo-border;
		box-shadow: $purple 0px 1px 8px 1px, $purple 0px 1px 5px 1px inset;
	}
}
// .btn-filmo span {
//     font-size: 1.4vw;
//     line-height: 2.6vw;
//     display: flex;
//     align-items: center;
//     text-align: center;
//     color: #FFFFFF;
// }
// /* phone */
// @media (max-width: 576px) {
// 	.btn-filmo span {
// 		font-size: 16px;
// 		line-height: 1.5;
// 	}
// }