.navbar {
    &.bg-dark {
        background-color: $body-bg-dark !important;
    }

    .navbar-brand {
        margin-left: 1.5em;
    }

    li {
        &.nav-item {
            margin: 0 2em;
            .nav-link {
                font-size: 16px;
                color: $font-grey;
                @include transition(all, 0.5s, ease-in-out);

                &.btn-filmo {
                    display: inline-block;
                    padding-left: 2em;
                    padding-right: 2em;
                }

                &:focus, &:hover {
                    color: $white;
                }
            }
            
        }
    }
}

@media (max-width: 576px) {
    .navbar {
        .navbar-brand {
            margin-left: 0;
            img {
                width: 6em;
            }
        }

        .navbar-toggler-icon {
            width: 1.3em;
        }

        li {
            &.nav-item {
                .nav-link {
                    &.btn-filmo {                        
                        padding: 5px 12px;
                        margin-top: 1em;
                    }
                }
                
            }
        }
    }
}

/* ipad, tablet (portrait) */
@media (min-width: 768px) and (max-width: 1024px) {
    .navbar {
        li {
            &.nav-item {
                .nav-link {
                    &.btn-filmo {                        
                        padding: 5px 12px;
                        margin-top: 1em;
                    }
                }
                
            }
        }
    }
}