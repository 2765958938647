@import "~bootstrap/scss/bootstrap.scss";
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700;800&family=Suez+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kodchasan:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

@import url('../../node_modules/font-awesome/css/font-awesome.css');
@import './landingpage/variables.scss';

@import './landingpage/container';
@import './landingpage/navbar';
@import './landingpage/button-typography';
@import './landingpage/accordion-tab';
@import './landingpage/form';
@import './landingpage/pagination';
@import './landingpage/footer';
@import './landingpage/new-footer';
@import './landingpage/new-navbar';

@import 'icon-fonts.scss';
// @import './landingpage/main.scss';

.blog-container {
	margin: 3em;
	img.top-image {
		border-radius: 15px;
		object-fit: cover;
		object-position: center;
		margin-bottom: 1em;
		display: block;
		width: 100%;
		height: 45vh;
	}
    .blog-list {
        margin-bottom: 3em;
		padding-bottom: 3em;
		border-bottom: solid 1px $white;
		&:last-child {
			border-bottom: none;
			margin-bottom: 0;
			padding-bottom: 0;
		}
        .blog-image {
            img {
                display: block;
                width: 90%;
                max-height: 190px;
                object-fit: cover;
				object-position: center;
                margin: auto;
                border-radius: 15px;
            }
        }
        .blog-info {
            .post-date {
                font-size: 14px;
            }
            .post-title {
                font-size: 21px;
                font-weight: 500;
            }
            .post-excerpt {
				font-size: 16px;
				font-weight: lighter;
				line-height: 32px;
            }
            .read-more-less {
                color: $font-grey;
				font-size: 16px;
				font-weight: lighter;
                text-decoration: none;
                @include transition(all, 0.5s, ease-in-out);

                &:focus, &:hover {
                    color: $white;
                }

				.fa-arrow-right {
					font-weight: lighter;
					margin-left: 10px;
					border: solid 2px;
					border-radius: 50%;
					padding: 5px 7px;
				}
            }
        }
    }
	.post-header {
		font-size: 21px;
    	margin: 1.5em 0;
	}
    .post-date {
		font-size: 16px;
	}
	.post-title {
		font-size: 21px;
		font-weight: 500;
	}
	.post-excerpt, .post-content {
		font-size: 16px;
		font-weight: lighter;
		line-height: 32px;
	}
    .read-more-less {
        color: $font-grey;
		font-size: 16px;
		font-weight: lighter;
		text-decoration: none;
		@include transition(all, 0.5s, ease-in-out);

		&:focus, &:hover {
			color: $white;
		}

		.fa-arrow-left {
			font-weight: lighter;
			margin-right: 10px;
			border: solid 2px;
			border-radius: 50%;
			padding: 5px 7px;
		}
    }
	.post-content-container {
		p {
			font-size: 16px;
			font-weight: lighter;
			line-height: 32px;
		}
		a {
			color: $filmo-gray;
			transition: .3s;
			&:hover {
				color: $white;
				text-decoration: underline;
			}
		}
		img {
			border-radius: 15px;
			object-fit: cover;
			object-position: center;
		}
	}
	ul.post-ul {
		font-size: 16px;
		font-weight: lighter;
    	line-height: 32px;
	}
	ul.post-ul {
		font-size: 16px;
		font-weight: lighter;
    	line-height: 32px;
	}
	ol.post-ol {
		font-size: 16px;
		font-weight: lighter;
    	line-height: 32px;

		&.ol-alpha-parenthesis {
			counter-reset: list;

			> li {
				list-style: none;
				position: relative;
				padding-left: .5em;
			}

			> li:before {
				counter-increment: list;
				content: "(" counter(list, lower-alpha) ") ";
				position: absolute;
				left: -1.4em;
			}
		}

		&.ol-roman-parenthesis {
			counter-reset: list;

			> li {
				list-style: none;
				position: relative;
				padding-left: .85em;
			}

			> li:before {
				counter-increment: list;
				content: "(" counter(list, lower-roman) ") ";
				position: absolute;
				left: -1.4em;
			}
		}

		&.ol-upper-alpha-parenthesis {
			counter-reset: list;

			> li {
				list-style: none;
				position: relative;
				padding-left: .5em;
			}

			> li:before {
				counter-increment: list;
				content: "(" counter(list, upper-alpha) ") ";
				position: absolute;
				left: -1.4em;
			}
		}
	}
}

.submitted-screen {
	position: relative;
	background: $black;
	border: none;
	margin: 0 auto 15px auto;
	max-width: 396px;	
	color: $white;
}

.submit-application-btn {
	width: 373.46px;
}

.login-screen {
	position: relative;
	background: $black;
	border: none;
	margin: auto;
	height: calc(100vh - 9.5em);
	display: flex;           // Add this
	flex-direction: column;  // Add this
	justify-content: center; // Add this
	align-items: center;    
	max-width: 400px;	
	width: 100%;
	color: $white;
	.login-logo {
		margin: 1rem 0 2rem 0;
		display: inline-flex;
		font-size: 2rem;
		font-weight: 700;
		color: $dark-blue;
		img {
			max-width: 170px;
		}
	}
	.login-box {
		padding: 0 1.5rem .5rem 1.5rem;
		width: 100%;
		h5 {
			margin: 0 0 1rem 0;
			font-size: .85rem;
			line-height: 150%;
			font-weight: 400;
		}
	}
	.form-control {
		color: $white !important;		
		background: $body-bg-dark;
		// border: 1px solid darken($white, 20%);
		border: none;
		&:focus {
			background: $white;
		}
	}
	.form-control:focus {
		caret-color: $white;
		color: $white;
		background: $body-bg-dark;
		border: none;
		margin: 5px 1px 3px 0px;
		box-shadow: none;
	}
	.custom-control-label::before {
		border: 1px solid darken($white, 20%);
		background: darken($white, 5%);
	}
	.custom-control-input:disabled~.custom-control-label,
	.custom-control-input[disabled]~.custom-control-label {
		color: #535c77;
	}
    
	.actions {
		margin-bottom: .5rem;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		a.link {
			color: $white;
			&:hover {
				color: lighten($white, 20%);
			}
		}
		.btn {
			margin-left: 10px;
		}
		&.align-left {
			justify-content: flex-start;
		}
	}
	.forgot-pwd {
		margin-bottom: .5rem;
		// display: flex;
		// justify-content: flex-start;
		align-items: center;
		a {
			color: $white;
			font-size: .8rem;
			transition: .3s;
			&:hover {
				color: $white;
			}
		}
	}
	.custom-control {
		min-height: auto;
	}
	p.info {
		padding: 0;
		margin: 0 0 20px 0;
		line-height: 150%;
		text-align: center;
	}
}

.application-submmit-screen {
	position: relative;
	background: $black;
	border: none;
	margin: 0 auto 0 auto;
	max-width: 905px;	
	color: $white;
	.input-group {
		height: 46px;
	}
	.login-logo {
		margin: 1rem 0 2rem 0;
		display: inline-flex;
		font-size: 2rem;
		font-weight: 700;
		color: $dark-blue;
		img {
			max-width: 170px;
		}
	}
	.login-box {
		padding: 0 1.5rem .5rem 1.5rem;
		h5 {
			margin: 0 0 1rem 0;
			font-size: .85rem;
			line-height: 150%;
			font-weight: 400;
		}
	}
	.form-control {
		color: $white !important;		
		background: $body-bg-dark;
		// border: 1px solid darken($white, 20%);
		border: none;
		font-size: inherit;
		&:focus {
			background: $white;
		}
	}
	.form-control:focus {
		caret-color: $white;
		color: $white;
		background: $body-bg-dark;
		border: none;
		margin: 5px 1px 3px 0px;
		box-shadow: none;
	}
	.form-control-placeholder::placeholder {
		color: $white;
	}
	.dropdown-form-control {
		color: $white !important;		
		background: $body-bg-dark;
		// border: 1px solid darken($white, 20%);
		border: none;
		height: 40px;
		font-size: 1rem;
		&:focus {
			background: $white;
		}
		&::placeholder {
			color: $white !important;
		}
	}
	.dropdown-form-control:focus {
		caret-color: $white;
		color: $white;
		background: $body-bg-dark;
		border: none;
		margin: 5px 1px 3px 0px;
		box-shadow: none;
	}
	.custom-control-label::before {
		border: 1px solid darken($white, 20%);
		background: darken($white, 5%);
	}
	.custom-control-input:disabled~.custom-control-label,
	.custom-control-input[disabled]~.custom-control-label {
		color: #535c77;
	}
    
	.actions {
		margin-bottom: .5rem;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		a.link {
			color: $white;
			&:hover {
				color: lighten($white, 20%);
			}
		}
		.btn {
			margin-left: 10px;
		}
		&.align-left {
			justify-content: flex-start;
		}
	}
	.forgot-pwd {
		margin-bottom: .5rem;
		// display: flex;
		// justify-content: flex-start;
		align-items: center;
		a {
			color: $white;
			font-size: .8rem;
			transition: .3s;
			&:hover {
				color: $white;
			}
		}
	}
	.custom-control {
		min-height: auto;
	}
	.custom-radio {
		padding-top: 4px;
	}
	p.info {
		padding: 0;
		margin: 0 0 20px 0;
		line-height: 150%;
		text-align: center;
	}
	.sub-page-title-header {
		margin: 0 0 1rem 0;
		font-size: .85rem;
		line-height: 150%;
		font-weight: 400;
	}
	.custom-checkbox .custom-control-label::before {
		@include roundedCorners(2);	
	}
	.custom-control-label {
		color: $white;
	}
	.custom-control-label::before {
		top: 0;
		border-color: $white;
		background: $body-bg-dark;
	}
	.custom-control-label::after {
		top: 0;
	}
	.custom-control-input:checked~.custom-control-label::before {
		background: $white;	
		border-color: $white;
	}
	.custom-control-input:checked~.custom-control-label::after {
		top: 2px;
		margin-left: 5px;
		width: 6px;
		height: 12px;
		border: solid $black;
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg); 
	}
	.custom-control.custom-switch .custom-control-label::after {
		top: 2px;
		background: $primary-color;
	}
	.custom-control-input:focus~.custom-control-label::before {
		box-shadow: none;
	}
	.custom-control-input:disabled~.custom-control-label::before {
		background-color: $body-bg;
		border-color: $form-border;
	}

	input[type=text] {
		&.as-dropdown {
			padding-left: 18px;
			height: 47px;
			border-left: solid 2px $white !important;
			border-top: solid 2px $white !important;
			border-bottom: solid 2px $white !important;
			border-right: none !important;
			border-radius: 14px 0 0 14px !important;
	
			&:focus {
				box-shadow: none !important;  
				margin: 5px 1px 3px 0px !important;
			}
	
			&.opened {
				border-bottom: none !important;
				border-radius: 14px 0 0 0 !important;
			}
	
			&::placeholder {
				color: #fff;
			}
		}
	}

	.input-group-carret {
		margin-top: 15px;
		cursor:pointer;
	
		&.carret-as-dropdown {
		background: $body-bg-dark;
		border-right: solid 2px $white !important;
		border-top: solid 2px $white !important;
		border-bottom: solid 2px $white !important;
		border-left: none !important;
		border-radius: 0 14px 14px 0 !important;
		margin-top: 5px;
		margin-bottom: 3px;
		padding-top: 13px;
		padding-right: 7px;
	
		&.opened {
			border-bottom: none !important;
			border-radius: 0 14px 0 0 !important;
		}
		}
	}

	.dropdown-search {
		margin-left: -1.8px;
		margin-top: -7px;
		position: absolute;
		background: $body-bg-dark;
		color: $white;
		left: 0;
		top: 38px;
		width: 101%;
		z-index: 2;
		display: none;
		max-height: 300px;
		overflow: auto;
		padding: 10px;
		border: solid 2px $white;
		border-top: none;
		border-radius: 0 0 14px 14px;

		&::-webkit-scrollbar{
			height: 6px;
			width: 6px;
			// background: $body-bg-dark;
			border-radius: 14px;
		}
		&::-webkit-scrollbar-thumb:horizontal {
			background: $font-grey;
			border-radius: 10px;
		}
		&::-webkit-scrollbar-thumb:vertical {
			background: $font-grey;
			border-radius: 10px;
		}
	}
}

a.sign-up-link {
	color: $white;
	transition: .3s;
	&:hover {
		color: $white;
	}
}

@media (max-width: 576px) {
	.blog-container {
		margin: 0;
		img.top-image {
			height: 23vh;
		}
        .blog-list {
            .blog-image {       
                margin-bottom: 2em;
				img {
					width: 100%;
				}
            }
        }
    }
}

@media (min-width: 1281px) {
	.blog-container {
		margin: 3em;
		&.detail {
			max-width: 900px;
			margin-left: auto;
			margin-right: auto;
		}
		&.list {
			max-width: 1200px;
			margin-left: auto;
			margin-right: auto;
		}
	}
}

