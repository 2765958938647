.new-navbar {
    position: relative;
    width: 100%;
    z-index: 1030;

    &.bg-dark {
        background-color: $body-bg-dark !important;
    }

    .navbar-brand {
        margin-left: 1.5em;
        img {
            width: 7.8em;
            height: auto;
        }
    }

    

    li {
        &.nav-item {
            margin: 0 2em;
            .nav-link {
                font-size: 16px;
                color: $white;
                @include transition(all, 0.5s, ease-in-out);

                &.btn-filmo {
                    display: inline-block;
                    padding-left: 2em;
                    padding-right: 2em;
                }

                &:focus, &:hover {
                    color: $white;
                }
            }
            
        }
    }

    .navbar-toggler {
        z-index: 1031;
        position: relative;
        border: none;
        padding: 0.5rem;

        &:focus {
            outline: none;
        }

        &[aria-expanded="true"] {
            .navbar-toggler-icon {
                background-image: url('../../vendors/images/icon/close.svg');
                width: 1.2em;
                height: 1.2em;
                filter: invert(35%);
                @media (max-width: 576px) {
                    width: 0.9em;
                    height: 0.9em;
                }
            }
        }
    }

    .navbar-collapse {
        @media (max-width: 765px) {
            position: absolute;
            top: 4em;
            right: 2em;
            border-radius: 3px;
            background: rgba(0, 0, 0, 0.26);
            transform: translateY(-10px);
            opacity: 0;
            visibility: hidden;
            transition: all 0.2s ease;
            text-align: right;

            &.show {
                transform: translateY(0);
                opacity: 1;
                visibility: visible;
            }
        }
    }

    li.nav-item {
        @media (max-width: 765px) {
            margin: 0;
            
            .nav-link {
                padding: 0.5em 0.5em;
                
                &:hover {
                    background-color: rgba(255, 255, 255, 0.1);
                }
            }
        }
    }
}

@media (max-width: 576px) {
    .new-navbar {
        .navbar-brand {
            margin-left: 0;
            img {
                width: 6em;
            }
        }

        .navbar-toggler-icon {
            width: 1.2em;
        }
        li {
            &.nav-item {
                .nav-link {
                    &.btn-filmo {                        
                        padding: 5px 12px;
                        margin-top: 1em;
                    }
                }
                
            }
        }

       
    }
}

/* ipad, tablet (portrait) */
@media (min-width: 768px) and (max-width: 1024px) {
    .new-navbar {
        li {
            &.nav-item {
                .nav-link {
                    &.btn-filmo {                        
                        padding: 5px 12px;
                        margin-top: 1em;
                    }
                }
                
            }
        }
    }
}