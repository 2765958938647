.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    min-height: 7em;
    line-height: 60px;
    background-color: $body-bg-dark;

    >.container {
        padding-right: 15px;
        padding-left: 15px;

        ul.footer-links {
            display: flex;
            list-style: none;
            margin-bottom: 0;
            justify-content: center;

            li.footer-links__item {
                display: list-item;
                font-size: 16px;
                line-height: 36px;

                &.active {
                    border-bottom: 1px solid $white;

                    a.footer-links__link {
                        color: $white;
                    }
                }

                a.footer-links__link {
                    text-decoration: none;
                    color: $font-grey;
                    @include transition(all, 0.5s, ease-in-out);

                    &:hover {
                        color: $white;
                    }

                    .footer-image {
                        max-width: 100px;
                        height: auto;
                        border-radius: 8px;
                        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
                        transition: transform 0.3s ease-in-out;

                        &:hover {
                            transform: scale(1.05);
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 1200px) {
    .container-footer {
        max-width: 1200px;
    }
}

@media (max-width: 576px) {
    .footer {
        line-height: 40px;
        padding-top: 10px;
        border-top: 1px solid $filmo-gray;

        >.container {
            ul.footer-links {
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-around;
                padding-left: 0;
                padding-bottom: 5px;

                li.footer-links__item {
                    font-size: 14px;

                    &.active {
                        border-bottom: none;
                    }
                }
            }
        }
    }
}